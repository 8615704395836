import { createReducer, on } from "@ngrx/store";
import User from "../../modals/user";
import { set_user_details, set_user_config_loading, update_user_details } from "../actions/user.action";

export const initialUserState: User = {
    userId: 0,
    userGuid: "",
    firstName: "",
    name: "",
    userName: "",
    email: "",
    isUsingPhoneNumber: false,
    alternateEmail: "",
    isUserActive: false,
    department: "",
    accounts: [],
    roles: [],
    userProfileUrl: "",
    allowedRewardTypes: [],
    isCompanyTagsAllwed: false,
    impersonatingUserId: 0,
    isSendRewardAllowed: false,
    isRecognitionAiEnabled: false,
    promptLimitReached: false,
    ipDetails: null,
    accountName: null,
    isWalletRedemptionAllowed: false,
    isWishlistLabAllowed: false,
    companyLogo: null,
    isManager: false,
    metaDataLoaded: false,
    phoneNumber: null,
    loading: true
};

function mapManualUser(firstName, lastName) {
    return
}

const _userReducer = createReducer(
    initialUserState,
    on(set_user_details, (state, props) => { return { ...state, ...props } }),
    on(update_user_details, (state, props) => { return { ...state, ...props } }),
);

export function UserReducer(state, action) {
    return _userReducer(state, action);
}

const _configReducer = createReducer(
    true,
    on(set_user_config_loading, (state, props) => { return props.loading }),
);

export function configReducer(state, action) {
    return _configReducer(state, action);
}