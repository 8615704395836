export enum ProviderLocalStorageKey {
  MicrosoftTeamsProvider = 'ms_team_provider',
  MicrosoftTeamsRefreshToken = 'uuishlist_ref_key'
}

export enum AppVersionKey {
  available_version = 'available_version',
  current_version = 'current_vesion'
}

export enum UserLocalStorageKey {
  User = 'user',
  GuestLogin = 'guest_login',
  AccessToken = 'access_token',
  TokenExpired = 'token_expired',
  UserConfig = 'user_config',
  AccountStatus = 'accountStatus',
  DefaultRoles = 'default_roles',
  ChangeUser = 'change_user',
  ContinueUrl = 'continue_url',
  RequestId = 'request_id',
  AccountSwitched = 'user_switched_account',
  RedirectUrl = 'redirect_url',
  RemoveUrl = 'remove_url',
  AccessDeniedPage = 'access-denied-page',
  UuishlistLoginId = 'uuishlist_login_id',
  SsoLoginType = 'sso_login_type',
  ExpiresIn = 'expires_in',
  PasswordResetInfo = 'password_reset_info',
  BookingInformation = 'booking_information',
  RedemptionAttribute = 'redemption_attribute',
  PageLoadCount = 'page_load_count',
  RecentSearches = 'recent_searches',
  User_location = 'user_location',
  Usr_po_code = 'usr_po_code',
  AccountSwitch= 'accountSwitch',
  Microsoft= 'microsoft',
  Sso_login_provider= 'sso_login_provider',
  preferenceSliderCountDownTimer = "preferenceSliderCountDownTimer",
  preferenceSliderSetOnUtc = "preferenceSliderSetOnUtc",
  lastPreferenceViewedIdentifier = "lastPreferenceViewedIdentifier",
  openPreferenceSelector = "openPreferenceSelector",
}

export enum UserCookieStorageKey {
  LoginDebug = 'uuishlist_debug_key',
  LoginOriginal = 'uuishlist_key_',
  EnvLocalhost = 'localhost',
  EnvOriginal = '.enjoywishlist.com',
  LoginIdDebug = 'uuishlist_debug_login_id',
  LoginIdOriginal = 'uuishlist_login_id_',
  SSOKey = 'uuishlist_sso_key',
  LoginKey = 'uuishlist_login_key',
  DynamicReaction = "dynamic_reaction",
  User = "user",
  XRefreshToken = 'X-Refresh-Token',
  XAccessToken = 'X-Access-Token'
}

export enum AccountUserPermissionKey {
  ShowSendRecognitionWidget = 'show_sent_recognition_widget',
  EnableApprovalThreshold = 'enable_approval_threshold',
  EnableCustomSurvey = 'enable_custom_survey',
  AccountId = 'user_logged_in_account_id',
  IsHrSystemEnabled = 'user_account_hr_system_integrated',
  CreditCardAccount = 'user_account_creditcard',
  CustomRewardAccess = 'user_custom_rewards_access',
  RewardTypeAllowed = 'user_rewards_types_allowed',
  SendRewardAccess = 'user_send_rewards_access',
  CompanyTagAccess = 'user_company_tags_access',
  RecognitionAccess = 'user_recognition_access',
  EnableInternationalAccess = 'user_Account_EnableInternational',
  UserUserGuid = 'user_user_guid',
  SeeBudgetAccess = 'isSeeBudgetAllowed',
  LaunchDate = "LaunchDate",
  FeedConfig = 'feed_config',
  SetUserAlternateEmail = 'set_user_recovery_email',
  FeedFeatureWalkThrough = 'feed_feature_walk_through',
  isSpotRewardAllowed = 'is_spot_reward_allowed',
  IsUserActive = 'is_user_active'
}

export enum ProfileImage {
  Updated = 'ProfileImageUpdated',
  Deleted = 'ProfileImageDeleted'
}
