import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserBudgetResetRequested } from '../../account/newsfeed/newsfeed.component/newsfeed.actions';
import { AppState } from '../../store/reducers';

@Injectable({ providedIn: 'root' })

export class RewardSomeoneService {
  public readonly reward_receiver_token_from_api: string = "[Reward.Receiver]";
  public readonly reward_receiver_token_to_display: string = "[Reward Receiver]";
  public readonly reward_receiver_token_to_Send: string = "%Reward.Receiver%";
  
  private wasRewardsSent: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private store: Store<AppState>) { }

  public setRewardsWereSent(value: boolean = true) {
    this.wasRewardsSent.next(value);
    if (value) { this.refreshRewardState(); }
  }

  public getRewardsWereSent(): Observable<boolean> {
    return this.wasRewardsSent;
  }

  refreshRewardState() {
    this.store.dispatch(new UserBudgetResetRequested())
  }

  replaceRewardReceiverTokens(users: any[], header: string, message: string): { header: string, message: string } {
    if (users?.length == 1) {
      let receiverName = users[0].firstName?.trim() ? users[0].firstName?.trim() : "";
      if (receiverName?.trim()) {
        receiverName = receiverName.charAt(0).toUpperCase() + receiverName.slice(1);
        header = header.replace(this.reward_receiver_token_from_api, receiverName);
        message = message.replace(this.reward_receiver_token_from_api, receiverName);
      }
    } else {
      header = header.replace(this.reward_receiver_token_from_api, this.reward_receiver_token_to_display);
      message = message.replace(this.reward_receiver_token_from_api, this.reward_receiver_token_to_display);
    }

    return { header, message };
  }
}
