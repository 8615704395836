import { Injectable } from "@angular/core";
import { LocalStorageService } from "../localstorage/localStorage.service";
import { AccountApiService } from "../api.service";
import { map, catchError, retry, timeout } from "rxjs/operators";
import { throwError, Observable, timer } from "rxjs";
import { UserRoleService } from './user.role.service';
import { UserRole } from '../../modals/keys/variable.keys';
import { AccountUserPermissionKey, UserLocalStorageKey } from '../../modals/keys/local.storage';
import { RewardType } from '../../modals/keys/reward.type';
import { permissionErrorMessage } from "../../modals/keys/error.key";
import { StoreService } from "../../store/store.service";
import { set_account_details, update_account_details } from "../../store/actions/account.action";
import User from "../../modals/user";
import Account from "../../modals/account";
import { UserGuardService } from "../authentication/user.guard.service";

@Injectable({ providedIn: 'root' })
export class AccountConfigService {
  private reactionDetail: any;
  private user: User;
  private account: Account;
  constructor(private localStorageService: LocalStorageService, private _accountApiService: AccountApiService, private userRoleService: UserRoleService, private storeService: StoreService, private _userGuardService:UserGuardService) {
    this.storeService.getUser().subscribe(user => this.user = user);
    this.storeService.getAccount().subscribe(account => this.account = account);
  }

  public getUserRoleFromStore(): string {
    return this.userRoleService?.getUserRoleFromStore(this.user?.roles);
  }

  setLoggedInUserPermissions(timeoutMs = 10000,attempt = 0): Observable<any> {
    return this._userGuardService.doesRewardSenderHaveAccess()
      ? this.setLoggedInUserPermissionApiCall().pipe(timeout(timeoutMs), catchError((error)=>{
        if(attempt === 1){
          return this.setLoggedInUserPermissions(5000,attempt+1);
        }
        return throwError(()=>error);
      }))
      : this.setLoggedInUserPermissionApiCall();
  }

  private setLoggedInUserPermissionApiCall():Observable<any>{
    return this._accountApiService.getAccountConfigData().pipe(
      map((response: any) => {
        this.storeService.getStore().dispatch(set_account_details({...response?.data, loading: false}));
        return true;
      }),
      catchError((error: any) => {
        if (!(error.status && (error.status == 403 || error.status == 401))) {
          error.message = permissionErrorMessage;
        }
        return throwError(error);
      }))
  }

  public getUserAccountHrSystemIntegratedPermissionValue(): boolean {
    return this.account?.isHrSystemEnabled ?? true;
  }

  public getUserAccountCreditCardPermissionValue(): boolean {
    return this.account?.isCreditCardEnabled ?? false;
  }

  public getUserCustomRewardPermissionValue(): boolean {
    return this.getUserRoleFromStore() == UserRole.ProgramAdmin && (this.account.isCustomRewardEnabled ?? false);
  }

  public getUserRewardsTypeAllowedOnAccount(): string[] {
    return this.account?.inviteTypesAllowed ?? null;
  }

  public getUserSendRewardsPermissionValue(): boolean {
    return this.account.isSpotRewardAllowedForSender && this.account.inviteTypesAllowed && this.account.inviteTypesAllowed.length > 0 && (this.account.inviteTypesAllowed.indexOf(RewardType.Spot) > -1)
  }

  public getUserCompanyTagsPermissionValue() {
    return this.account?.inviteTypesAllowed?.length > 0 && (this.account?.inviteTypesAllowed.indexOf(RewardType.Spot) > -1);
  }

  public getUserRecognitionPermissionValue() {
    return this.account?.isRecognitionEnabled ?? false;
  }

  public getEnableInternationalOnAccount() {
    return this.account?.isInternationalEnabled ?? false;
  }

  getIsSeeBudgetAllowed() {
    return this.account?.isSeeBudgetAllowed ?? false;
  }

  setAllowDefaultRole(isAllowed: boolean) {
    this.storeService.getStore().dispatch(update_account_details({ defaultRoles: isAllowed }))
  }

  public setReactionEmojisData(reactionDetail) {
    this.reactionDetail = reactionDetail;
  }

  public getReactionEmojisData() {
    return this.reactionDetail;
  }

  public getShowSendRecognitionWidget(): boolean {
    return this.account?.feedStatsWidgetVisible ?? false;
  }

  private setIsEnabledCustomSurveyAllowed(value: boolean): void {
    this.storeService.getStore().dispatch(update_account_details({ hasCustomSurveys: value ?? false }));
  }

  public getIsEnabledCustomSurveyAllowed(): boolean {
    return this.account?.hasCustomSurveys ?? false;
  }

  public getUserNeedToSetRecoveryEmail(): boolean {
    return this.account?.setAlternateEmail ?? false;
  }

  public getUserNeedToFillOnboardSurvey(): boolean {
    return this.account?.isOnboardingSurveyRequired ?? false;
  }

  public getIsUserActive(): boolean {
    return this.user?.isUserActive ?? false;
  }

  public setIsRewardSomeoneEnabled(value) {
    this.storeService.getStore().dispatch(update_account_details({ isSpotRewardAllowed: value }));
  }

  public getIsRewardSomeoneEnabled() {
    return this.account?.isSpotRewardAllowed ?? false;
  }

  public getIsSpotProgramSelectionEnabled(): boolean {
    return this.account?.isSpotProgramSelectionEnabled ?? false;
  }

  public getIsRewardSpecificityAllowed(): boolean {
    return this.account?.isRewardSpecificityAllowed ?? false;
  }

  public isRewardSelfServiceAllowed() {
    return this.account?.hasRewardSelfService ?? false;
  }

  public hasAccountRecognitionAIEnabled(): boolean {
    return this.account?.isRecognitionAiEnabled ?? false;
  }
  public hasUserRecognitionAIEnabled(): boolean {
    return this.user?.isRecognitionAiEnabled ?? false;
  }

  public hasRecognitionAIPromptLimitReached(): boolean {
    return this.user?.promptLimitReached ?? false;
  }

  public isSpotRewardAllowed(): boolean {
    return (this.account?.isSpotRewardAllowed ?? false) && (this.account?.isSpotRewardAllowedForSender ?? false);
  }
  
  public isSmsLoginEnabled(): boolean {
    return this.account?.isSmsLoginEnabled ?? false;
  }

  clearUserPermissionDetails() {
    this.localStorageService.remove(AccountUserPermissionKey.AccountId);
    this.localStorageService.remove(AccountUserPermissionKey.IsHrSystemEnabled);
    this.localStorageService.remove(AccountUserPermissionKey.CreditCardAccount);
    this.localStorageService.remove(AccountUserPermissionKey.CustomRewardAccess);
    this.localStorageService.remove(AccountUserPermissionKey.RewardTypeAllowed);
    this.localStorageService.remove(AccountUserPermissionKey.SendRewardAccess);
    this.localStorageService.remove(AccountUserPermissionKey.CompanyTagAccess);
    this.localStorageService.remove(AccountUserPermissionKey.RecognitionAccess);
    this.localStorageService.remove(AccountUserPermissionKey.EnableInternationalAccess);
    this.localStorageService.remove(AccountUserPermissionKey.UserUserGuid);
    this.localStorageService.remove(AccountUserPermissionKey.SeeBudgetAccess);
    this.localStorageService.remove(UserLocalStorageKey.AccountStatus);
    this.localStorageService.remove(AccountUserPermissionKey.ShowSendRecognitionWidget);
    this.localStorageService.remove(AccountUserPermissionKey.EnableCustomSurvey);
    this.localStorageService.remove(AccountUserPermissionKey.SeeBudgetAccess);
    this.localStorageService.remove(AccountUserPermissionKey.SetUserAlternateEmail);
    this.localStorageService.remove(AccountUserPermissionKey.IsUserActive);
    this.localStorageService.remove(AccountUserPermissionKey.isSpotRewardAllowed);
  }

  public getNotificationBannerData() {
    return this.account?.notificationBannerData ?? null;
  }
  public getIsWalletRedemptionAllowed() {
    return this.account?.isWalletRedemptionAllowed ?? null;
  }

  public setApprovalDetails() {
    this._accountApiService.getApprovalDetails().subscribe(response => {
      if (response?.data) {
        this.storeService.getStore().dispatch(update_account_details({ isApprovalProcessAllowed: response.data?.isApprovalProcessAllowed, primaryApprover: response.data.primaryApprover }));
      }
    })
  }

  public getApprovalProcessAllowed(): boolean {
    return this.account?.isApprovalProcessAllowed ?? false;
  }

  public defaultProgramApprover(): string | null {
    return this.account?.primaryApprover ?? null;
  }
}
