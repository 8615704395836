import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { WlSnackbarComponent } from '../component';
import { WlSnackbarConfig, WlSnackbarData } from '../model/wl-snackbar.model';

@Injectable({
  providedIn: 'root'
})

export class WlSnackbarService {
  public constructor(private _snackbar: MatSnackBar) { }

  public openSnackBar(config: WlSnackbarConfig): MatSnackBarRef<WlSnackbarComponent> {
    if (!config?.data?.message) { return null }

    let snackBarConfig = new MatSnackBarConfig<WlSnackbarData>();
    snackBarConfig.panelClass = ['wl-snackbar'];
    snackBarConfig.verticalPosition = config?.verticalPosition;
    snackBarConfig.horizontalPosition = config?.horizontalPosition;
    snackBarConfig.duration = config?.duration;
    snackBarConfig.data = config?.data;

    return this._snackbar.openFromComponent(WlSnackbarComponent, snackBarConfig);
  }

  dismissSnackBar() {
    this._snackbar.dismiss();
  }
}
