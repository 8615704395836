import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { UserRewardActionTypes } from "./user.rewards.actions";
import { Reward } from "../../modals/user.reward";

export interface UserRewardState extends EntityState<Reward> {
    hasNextPage?: boolean,
    isLoaded?: boolean,
    errorMessage?: string;
    isLoading?: boolean;
}

export const rewardAdapter: EntityAdapter<Reward> = createEntityAdapter<Reward>(
    { selectId: (reward: Reward) => reward.identifier }
);

const initialActivityState = rewardAdapter.getInitialState({
    isLoading : true
});

export function userRewardReducer(state = initialActivityState, action): UserRewardState {
    switch (action.type) {
        case UserRewardActionTypes.RewardRequested:

            return { ...state, isLoaded: false, isLoading: true };

        case UserRewardActionTypes.RewardLoaded:

            return rewardAdapter.addMany(action.payload.userReward, {
                ...state, hasNextPage: action.payload.hasNextPage,
                isLoaded: action.payload.isLoaded, isLoading: false
            });

        case UserRewardActionTypes.RewardResetCompleted:

            return rewardAdapter.setAll(action.payload.userReward, { ...state, hasNextPage: action.payload.hasNextPage, isLoaded: true });

        case UserRewardActionTypes.RewardLoadingError:

            return { ...state, hasNextPage: true, isLoaded: false, errorMessage: action.payload.errorMessage, isLoading: false }

        default:

            return { ...state, hasNextPage: true, isLoaded: false };

    }
}

const loadingInitialState = false;
export function rewardLoadingReducer(state = loadingInitialState, action) {
    switch (action.type) {
        case UserRewardActionTypes.RewardLoading:
            return action.payload.isLoading;
        default:
            return state;
    }
}

const isRewardAllowedInitialState = false;
export function isRewardAllowedReducer(state = isRewardAllowedInitialState, action) {
    switch (action.type) {
        case UserRewardActionTypes.isRewardAllowedRequested:
            return false;
        case UserRewardActionTypes.isRewardAllowedLoaded:
            return true;
        default:
            return state;
    }
}



export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = rewardAdapter.getSelectors();