import { createReducer, on } from "@ngrx/store";
import { MarketplaceNestedCategories } from "../../model/category-subcategory";
import { set_marketplace_config, set_marketplace_config_loading, set_marketplace_currencies, set_marketplace_granularPreferences, set_marketplace_listing, set_marketplace_subcategory, update_marketplace_config } from "../actions";
import { MarketplaceListing } from "../../model/listing";
import { MarketplaceConfig, MarketplaceGranularPreference } from "../../model/wallet";
import { MarketplaceFilter } from "../../model/filter";
import { MarketplaceCurrencyAvailable } from "../../model/currency";
import { set_marketplace_filter } from "../../../store/actions/user.action";

const nestedCategoryInitialState: MarketplaceNestedCategories = { data: [], isLoading: false } as MarketplaceNestedCategories;

const _marketplaceSubCategories = createReducer(
    nestedCategoryInitialState,
    on(set_marketplace_subcategory, (state, props) => props)
);
export function marketplaceSearchCategoryReducer(state, action) {
    return _marketplaceSubCategories(state, action);
}

const listingInitialState: MarketplaceListing = {} as any;

const _marketplaceListing = createReducer(
    listingInitialState,
    on(set_marketplace_listing, (state, props) => props)
);

export function marketplaceListingReducer(state, action) {
    return _marketplaceListing(state, action);
}

export const configInitialState: MarketplaceConfig = { currencyCode: "USD", banners: [], hasFavouriteProduct: false, categories: [], isLoading: true, setUserWalletCurrency: false };

const _marketplaceConfig = createReducer(
    configInitialState,
    on(set_marketplace_config, (state, props) => props),
    on(update_marketplace_config, (state, props) => { return { ...state, ...props } }),
    on(set_marketplace_config_loading, (state, props) => { return { ...state, ...props } }),
);

export function marketplaceConfigReducer(state, action) {
    return _marketplaceConfig(state, action);
}

const filterInitialState: MarketplaceFilter = {} as any;

const _marketplaceFilters = createReducer(
    filterInitialState,
    on(set_marketplace_filter, (state, props) => props)
);

export function marketplaceFiltersReducer(state, action) {
    return _marketplaceFilters(state, action);
}

const currencyInitialState: MarketplaceCurrencyAvailable[] = [];

const _marketplaceCurrencies = createReducer(
    currencyInitialState,
    on(set_marketplace_currencies, (state, props) => props.data)
);

export function marketplaceCurrenciesReducer(state, action) {
    return _marketplaceCurrencies(state, action);
}

const _marketplaceGranularPreference = createReducer(
    null,
    on(set_marketplace_granularPreferences, (state, props: MarketplaceGranularPreference) => props)
);

export function marketplaceGranularPreferenceReducer(state, action) {
    return _marketplaceGranularPreference(state, action);
}