import { createAction, props } from '@ngrx/store';
import { MarketplaceConfig, MarketplaceGranularPreference } from '../../model/wallet';
import { MarketplaceFilter } from '../../model/filter';
import { MarketplaceListing } from "../../model/listing";
import { MarketplaceNestedCategories } from "../../model/category-subcategory";
import { MarketplaceCurrencyAvailable } from '../../model/currency';

export const set_marketplace_config = createAction('MARKETPLACE_SET_CONFIG', props<MarketplaceConfig>());
export const update_marketplace_config = createAction('MARKETPLACE_UPDATE_CONFIG', props<MarketplaceConfig>());
export const set_marketplace_listing = createAction('MARKETPLACE_SET_LISTING', props<MarketplaceListing>());
export const set_marketplace_subcategory = createAction('MARKETPLACE_SET_SUBCATEGORY', props<MarketplaceNestedCategories>())
export const set_marketplace_currencies = createAction('MARKETPLACE_SET_CURRENCIES', props<{ data: MarketplaceCurrencyAvailable[] }>())
export const set_marketplace_config_loading = createAction('MARKETPLACE_CONFIG_LOADING', props<{ isLoading: boolean }>());
export const set_marketplace_granularPreferences = createAction('MARKETPLACE_GRANULAR_PREFERENCES_SET', props<MarketplaceGranularPreference>());
