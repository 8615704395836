import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { LocalStorageService } from '../localstorage/localStorage.service';
import { AuthService } from '../authentication/auth.service';
import { switchMap, catchError, filter, take } from 'rxjs/operators';
import { HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { UserLocalStorageKey } from '../../modals/keys/local.storage';
import { CookieStorageService } from "../cookiestorage/cookieStorage.service";
@Injectable({ providedIn: 'root' })
export class RequestHandleService {
  readonly oAuthUrl: string = 'api/v2/oauth';
  readonly tokenValidateUrl: string = 'api/v1/account/validate';
  readonly accountV2Tfa: string = 'api/v2/account/tfa';
  readonly accountV1User: string = 'api/v1/user';
  readonly accountV1: string = 'api/v1/account';
  readonly accountV2: string = 'api/v2/account/check/token/validity';
  readonly catalogV2: string = 'api/v2/catalog';
  readonly userAnalytics: string = 'api/user/analytics';
  readonly validateCommEmail: string = 'api/v2/user/add/email/validate';
  readonly setCommEmail: string = 'api/v2/user/add/email';
  readonly privacyPolicy: string = 'api/v2/user/privacy/policy/accept';
  readonly message: string = "Session expired, please sign in again to continue.";

  private isRefreshingToken: boolean;
  private tokenSubject: BehaviorSubject<boolean>;

  constructor(private localStorageService: LocalStorageService, private authService: AuthService, private cookieStorageService: CookieStorageService) {
    this.isRefreshingToken = false;
    this.tokenSubject = new BehaviorSubject<boolean>(false);
  }
  private isRequestUrlAuthUrl(request: any): boolean {
    return (request.url.indexOf(this.oAuthUrl) > -1) || (request.url.indexOf(this.tokenValidateUrl) > -1 || (request.url.indexOf(this.accountV2Tfa) > -1) || request.url.indexOf(this.accountV1User) > -1 || request.url.indexOf(this.accountV1) > -1) || (request.url.indexOf(this.accountV2) > -1) || (request.url.indexOf(this.catalogV2) > -1) || (request.url.indexOf(this.userAnalytics) > -1) 
    || request.url.indexOf(this.validateCommEmail) > -1 || request.url.indexOf(this.setCommEmail) > -1 || request.url.indexOf(this.privacyPolicy) > -1;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let loginId = this.localStorageService.get(UserLocalStorageKey.UuishlistLoginId);
    let guestLogin = this.localStorageService.get(UserLocalStorageKey.GuestLogin);
    let cookieValue = this.authService.getLoginCookies();
    let isAppHostedOnMsTeam = this.authService.getIsAppHostedOnMsTeam();

    //this is check if api call url is for oauth
    if (!this.isRequestUrlAuthUrl(request)) {
      //This is to check the validity of access token if present.
      let isAccessTokenValid = this.isAccessTokenValid();
      if (!isAccessTokenValid) {
        return this.updateRefreshToken(request, next);
      }
      else if ((((cookieValue && cookieValue == loginId)) || guestLogin || isAppHostedOnMsTeam) && isAccessTokenValid) {
        request = request.clone({ setHeaders: this.setApiHeaders() });
      }
      else {
        this.authService.logout(true);
      }
    }

    return next.handle(request).pipe(catchError(error => {
      return this.handleResponseError(error, request, next);
    }));
  }

  private updateRefreshToken(request, next): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(false);
      return this.authService.getNewAccessToken()
        .pipe(switchMap((event: any) => {
          this.isRefreshingToken = false;
          if (event) {
            this.tokenSubject.next(true);
            return this.proceedRequestAfterAddingToken(request, next);
          }
          else {
            this.authService.logout(true);
          }
        }), catchError((error) => {
          this.isRefreshingToken = false;
          this.authService.logout(true);
          return throwError(error);
        }));
    }
    else {
      return this.tokenSubject
        .pipe(
          filter(token => token == true)
          , take(1)
          , switchMap((value) => {
            return this.proceedRequestAfterAddingToken(request, next);
          })
        );
    }
  }

  private handleResponseError(error, request?, next?): Observable<any> {
    if (error.status == 401 && !this.isRequestUrlAuthUrl(request)) {
      return this.updateRefreshToken(request, next);
    }
    return throwError(error);
  }

  private proceedRequestAfterAddingToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const newReq = request.clone({ setHeaders: this.setApiHeaders() });
    return next.handle(newReq);
  }

  private setApiHeaders() {
    let header = {
      'Access-Control-Allow-Origin': '*',
      'Cache-Control': 'no-store,no-cache,must-revalidate',
      'Pragma': 'no-cache',
      'withCredentials': 'true',
      'Access-Control-Allow-Credentials': 'true'
    }

    return header;
  }

  private isAccessTokenValid(): boolean {
    let expireDate = this.localStorageService.get(UserLocalStorageKey.ExpiresIn);
    if (!expireDate) { this.authService.logout(true); return false; }
    if (new Date(expireDate) < new Date()) { this.localStorageService.set(UserLocalStorageKey.TokenExpired, true); return false; }
    else { this.localStorageService.remove(UserLocalStorageKey.TokenExpired); return true; }
  }
}
