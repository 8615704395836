import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SnackbarAction } from '../../modals/snackbar';
import { ToastCloseService } from './toaster.close.service';
import { CommonUtilityService } from '../api.service';
import { WlSnackbarInfoType, WlSnackbarType } from 'projects/wl-snackbar';

@Injectable({ providedIn: 'root' })
export class ToastService {

  constructor(private readonly router: Router = inject(Router), private readonly toastCloseService: ToastCloseService = inject(ToastCloseService), private readonly _commonUtilityService: CommonUtilityService = inject(CommonUtilityService)) { }

  private isAdminUrl(): boolean {
    let url = this.router.url;
    let splitUrl = url.split(/[/?]/);
    return (splitUrl[2]?.indexOf('admin') > -1);
  }

  private isLoginPageUrl(): boolean {
    let url = this.router.url;
    let splitUrl = url.split(/[/?]/);
    return ((splitUrl[2]?.indexOf('login') > -1) || (splitUrl[2]?.indexOf('resetpassword') > -1) || (splitUrl[2]?.indexOf('onboarding') > -1));
  }

  public openToast(message: string, actionType: SnackbarAction = null, values: any = null, align: MatSnackBarHorizontalPosition = 'right', onlyToaster: boolean = false): void {
    this._commonUtilityService.openSnackbarMessage(message, WlSnackbarType.Normal, WlSnackbarInfoType.None);
  }

  closeToaster() {
    this.toastCloseService.setCloseToastValue(true);
  }
}
