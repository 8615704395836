import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { delay } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class InfoApiService {
  constructor(private configService: ConfigService) { }
  getWalletUserConfigData() {
    return this.configService.get("api/v2/user/wallet");
  }
  getBrainTreePaymentToken() {
    return this.configService.get('api/v2/catalog/paymenttoken');
  }
  getUserRewardRecognitionPreference(params: object) {
    return this.configService.get("api/v2/user/user/question", params);
  }
  updateUserRewardRecognitionPreference(params: object) {
    return this.configService.post("api/v2/user/user/question", params);
  }

  UpdateUserPreferenceSurveyrDisplayCount() {
    return this.configService.post("api/v2/user/preferences/survey/count", null);
  }

  getNotificationBar() {
    return this.configService.get("api/v2/user/notificationbar/get");
  }

  markNotificationRead(identifier: string, data = null) {
    return this.configService.post(`api/v2/user/marknotification/read?identifier=${identifier}`, data,);
  }
  
  getApprovalDetails = () => this.configService.get(`api/v2/account/approval/additional/details`);
  
}