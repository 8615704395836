import { Injectable } from '@angular/core';
import User from '../../modals/user';
import { StoreService } from '../../store/store.service';

@Injectable({ providedIn: 'root' })

export class RecognitionHandlerService {
  private user : User;
  constructor(private storeService : StoreService) { 
    this.storeService.getUser().subscribe(user => this.user = user);
  }
  public handlerRecognitionData(recognitionData): any {
    recognitionData?.map((element) => {
      element.id = element.guid;
      element.isSentByCurrentUser = element.createdBy.guid == this.user.userGuid;
      element.isEditStateMessage = false;
      element.isExpandedReadMore = false;
      element.sendKinection = { peopleList: [] };
      {
        element.hasMoreComments = element.hasMoreComments ? element.hasMoreComments : ((element.commentCount + element.kinectCount) > 3);
        element.commentsAndKinections = element.commentsAndKinections ? element.commentsAndKinections : element.feedComment;
      }
      {
        if (element.commentsAndKinections && element.commentsAndKinections.length > 0) {
          element.commentsAndKinections.map((commentsAndKinection) => {
            commentsAndKinection.id = commentsAndKinection.guid;
            commentsAndKinection.createdBy.isCurrentUser = commentsAndKinection.createdBy.guid == this.user.userGuid;
            commentsAndKinection.isEditState = false;
            commentsAndKinection.subReplyData = {
              subReplyCount: (commentsAndKinection.subReplyData && commentsAndKinection.subReplyData.subReplyCount) ? commentsAndKinection.subReplyData.subReplyCount : 0,
              firstSubReplier: (commentsAndKinection.subReplyData && commentsAndKinection.subReplyData.firstSubReplier) ? commentsAndKinection.subReplyData.firstSubReplier : undefined,
              subReplyIndex: commentsAndKinection.subReplyData.subReplyIndex ? commentsAndKinection.subReplyData.subReplyIndex : 0,
              isMoreDataAvailable: commentsAndKinection.subReplyData.isMoreDataAvailable ? commentsAndKinection.subReplyData.isMoreDataAvailable : false,
              subReplies: (commentsAndKinection.subReplyData.subReplies && commentsAndKinection.subReplyData.subReplies.length > 0) ? commentsAndKinection.subReplyData.subReplies : [],
            };
            commentsAndKinection.subReplyData.subReplies.map((subComment) => {
              subComment.id = subComment.guid;
              subComment.createdBy.isCurrentUser = commentsAndKinection.createdBy.guid == this.user.userGuid;
              subComment.isEditState = false;
            })
          });
        }
      }
      {
        element.postComment = { message: '', coworkerSuggesstions: false, peopleList: [], tags: [], imageUrl: undefined, imageType: undefined, imageFile: undefined, imageGuid: undefined, isMessageAvailable: false };
      }
      {
        if (element.reactedComment && element.reactedComment.length > 0) {
          element.reactedComment.map((react) => {
            react.isCommentInEditState = false;
            react.editableComment = react.comment;
            react.editableImageUrl = react.imageUrl;
            react.editableImageType = react.imageType;
            react.editableEmojisBoxShowed = false;
          })
        }
      }
    });

    let data = recognitionData?.filter((object, index, self) => { return index == self.findIndex(element => element.guid == object.guid) });

    return data;
  }
}
