const baseCdnUrl: string = "https://static.enjoywishlist.com";
const devBaseCdnUrl: string = "https://uuishlist.blob.core.windows.net";
export const image_kinect_primary_24_24: string = `${baseCdnUrl}/assets/static/kinect_primary_24_24.svg`;
export const image_kinect_highlight_24_24: string = `${baseCdnUrl}/assets/static/kinect_highlight_24_24.svg`;
export const image_reward_primary_24_24: string = `${baseCdnUrl}/assets/static/reward_primary_24_24.svg`;
export const image_reward_highlight_24_24: string = `${baseCdnUrl}/assets/static/reward_highlight_24_24.svg`;
export const image_reward_disable_24_24: string = `${baseCdnUrl}/assets/static/reward_disable_24_24.svg`;
export const image_like_primary_24_24: string = `${baseCdnUrl}/assets/static/like_primary_24_24.svg`;
export const image_like_highlight_24_24: string = `${baseCdnUrl}/assets/static/like_hightlight_24_24.svg`;
export const image_like_primary_16_16: string = `${baseCdnUrl}/assets/static/like_primary_16_16.svg`;
export const image_gif_primary_24_24: string = `${baseCdnUrl}/assets/static/gif_primary_24_24.svg`;
export const image_gif_disable_24_24: string = `${baseCdnUrl}/assets/static/gif_disable_24_24.svg`;
export const image_smile_emoji_disable_24_24: string = `${baseCdnUrl}/assets/static/smile_emoji_disable_24_24.svg`;
export const image_smile_emoji_primary_24_24: string = `${baseCdnUrl}/assets/static/smile_emoji_primary_24_24.svg`;
export const image_photo_primary_24_24: string = `${baseCdnUrl}/assets/static/photo_primary_24_24.svg`;
export const image_photo_disable_24_24: string = `${baseCdnUrl}/assets/static/photo_disable_24_24.svg`;
export const image_recognition_primary_24_24: string = `${baseCdnUrl}/assets/static/recognition_primary_24_24.svg`;
export const image_announcement_primary_24_24: string = `${baseCdnUrl}/assets/static/announcement_primary_24_24.svg`;
export const image_bamboo_hr_primary = `${baseCdnUrl}/assets/static/bamboo_hr_primary.png`;
export const image_calendar_primary = `${baseCdnUrl}/assets/static/calendar_primary.svg`;
export const image_calender_highlight = `${baseCdnUrl}/assets/static/calender_highlight.svg`;
export const image_download_primary = `${baseCdnUrl}/assets/static/download_primary.svg`;
export const image_download_transparent = `${baseCdnUrl}/assets/static/download_transparent.svg`;
export const image_download_highlight = `${baseCdnUrl}/assets/static/download_highlight.svg`;

//#region Icons
export const image_icon_google = `${baseCdnUrl}/assets/static/google.png`;
export const image_icon_microsoft = `${baseCdnUrl}/assets/static/microsoft.png`;
export const image_icon_azure = `${baseCdnUrl}/assets/static/azure.png`;
export const image_icon_okta = `${baseCdnUrl}/assets/static/okta.png`;
export const image_icon_sharepoint = `${baseCdnUrl}/assets/static/sharepoint.png`;
export const image_icon_warn_safron = `${baseCdnUrl}/assets/static/warn_safron.svg`;
export const image_icon_waiting_clock = `${baseCdnUrl}/assets/static/waiting_clock.svg`;
export const image_icon_success_tick_green = `${baseCdnUrl}/assets/static/success_tick.svg`;
export const image_icon_add_file_blue = `${baseCdnUrl}/assets/static/ic_add.svg`;
export const image_icon_blue_arrow_down = `${baseCdnUrl}/assets/static/ic_arrow_down.svg`;
export const image_icon_blue_arrow_left = `${baseCdnUrl}/assets/static/ic_arrow_left.svg`;
export const image_icon_blue_arrow_right = `${baseCdnUrl}/assets/static/ic_arrow_right.svg`;
export const image_icon_blue_arrow_up = `${baseCdnUrl}/assets/static/ic_arrow_up.svg`;
export const image_icon_blue_shopping_cart = `${baseCdnUrl}/assets/static/ic_shopping_cart.svg`;
export const image_icon_black_shopping_cart = `${baseCdnUrl}/assets/wallet-marketplace/icon_shopping_cart_black.svg`;
//#endregion

export const image_login_left = `${baseCdnUrl}/assets/static/left_login_image.png`;
export const image_login_right = `${baseCdnUrl}/assets/static/right_login_image.png`;
export const image_login_white_pollygon = `${baseCdnUrl}/assets/static/white_bg_pollygon.png`;
export const image_location_primary = `${baseCdnUrl}/assets/wallet-marketplace/ic_location.svg`;
export const image_favorite_added = `${baseCdnUrl}/assets/static/favorite_added.svg`;
export const image_favorite_empty = `${baseCdnUrl}/assets/static/favorite_empty.svg`;
export const image_travel_pdp = `${baseCdnUrl}/images/marketplace/travel/travel_pdp.png`;
export const reward_bouquet_icon = `${baseCdnUrl}/assets/static/ic_reward_bouquet.svg`;
export const reward_bouquet_wallet_icon = `${baseCdnUrl}/assets/static/ic_wallet.svg`;
export const image_favorite_list_empty = `${baseCdnUrl}/assets/wallet-marketplace/img_favorite_list_empty.svg`;
export const image_wallet_icon_blue = `${baseCdnUrl}/assets/wallet-marketplace/icon_wallet_blue.svg`;
export const image_unlimited_budget_image = `${baseCdnUrl}/assets/wallet-marketplace/icon_unlimited_budget_image.svg`;
export const image_none_budget_image = `${baseCdnUrl}/assets/wallet-marketplace/ic_none_budget_image.svg`;
export const animated_my_wishlist_add = `${baseCdnUrl}/assets/wallet-marketplace/gif_add_wishlist.gif`;
export const image_my_wishlist_empty = `${baseCdnUrl}/assets/wallet-marketplace/img_empty_cart.png`;
export const image_loginV2_left = `${baseCdnUrl}/assets/static/img_left_loginV2.png`;

//#region viator specific images
export const clock_black = `${baseCdnUrl}/assets/wallet-marketplace/ic_clock_black.svg`;
export const start_rating_empty = `${baseCdnUrl}/assets/wallet-marketplace/ic_start_rating_empty.svg`;
export const filled_star_rating = `${baseCdnUrl}/assets/wallet-marketplace/ic_filled_star_rating.svg`;
export const img_unavailable_viator_date = `${baseCdnUrl}/assets/wallet-marketplace/img_unavailable_viator_date.svg`;

export const badge_of_excellence = `${baseCdnUrl}/assets/wallet-marketplace/ic_badge_of_excellence.svg`;
export const best_seller = `${baseCdnUrl}/assets/wallet-marketplace/ic_best_seller.svg`;
export const likely_to_sell = `${baseCdnUrl}/assets/wallet-marketplace/ic_likely_to_sell.svg`;
export const once_in_a_lifetime = `${baseCdnUrl}/assets/wallet-marketplace/ic_once_in_a_lifetime.svg`;
export const quality_badge = `${baseCdnUrl}/assets/wallet-marketplace/ic_quality_badge.svg`;
export const three_user_icon_black = `${baseCdnUrl}/assets/wallet-marketplace/ic_three_user_icon_black.svg`;
export const unique_expereince = `${baseCdnUrl}/assets/wallet-marketplace/ic_unique_expereince.svg`;
//#endregion

export const ic_tch_app_blk: string = `${baseCdnUrl}/assets/static/ic_tch_app_blk.svg`;
export const ic_ppl_blk: string = `${baseCdnUrl}/assets/static/ic_ppl_blk.svg`;
export const ic_assit_nav_blk: string = `${baseCdnUrl}/assets/static/ic_assit_nav_blk.svg`;
export const ic_chat_blk: string = `${baseCdnUrl}/assets/static/ic_chat_blk.svg`;
export const ic_magnifying_glass_search_White: string = `${baseCdnUrl}/assets/static/ic_magnifying_glass_search_white.svg`;
// expose send reward dialog
export const animated_gift: string = `${baseCdnUrl}/assets/static/gif_reward_1.gif`;
export const feature_seasonal_gift: string = `${baseCdnUrl}/assets/static/ic_featured_seasonal_and_gifts.svg`;
export const market_place_reward: string = `${baseCdnUrl}/assets/static/ic_market-place-reward.svg`;

export const image_recognition_secondary_24_24: string = `${baseCdnUrl}/assets/static/recognition_secondary_24.svg`;
export const filter_icon_blue: string = `${baseCdnUrl}/assets/static/filter_primary_16_16.svg`;
export const sparkle_icon_left: string = `${baseCdnUrl}/assets/static/img_sparkle-left.png`;
export const sparkle_icon_right: string = `${baseCdnUrl}/assets/static/img_sparkle-right.png`;
export const knapsack: string = `${baseCdnUrl}/assets/static/reward_knapsack_send_reward.svg`;
export const gift_gif: string = `${baseCdnUrl}/assets/static/gift_send_reward.gif`;
export const empty_folder: string = `${baseCdnUrl}/assets/static/empty_folder_send_reward.svg`;
export const already_rewarded: string = `${baseCdnUrl}/assets/recognition/img_recog_already_rewarded.svg`;
export const user_group: string = `${baseCdnUrl}/assets/recognition/ic_user_group.svg`;
export const left_login_disabled: string = `${baseCdnUrl}/assets/static/img_left_login-disabled_image.svg`;
export const right_login_disabled: string = `${baseCdnUrl}/assets/static/img_right_login_disabled_image.svg`;
export const account_disabled_center: string = `${baseCdnUrl}/assets/static/img_account_disabled_center.svg`;
export const account_suspended_center: string = `${baseCdnUrl}/assets/static/img_account_suspended_center.svg`;
export const green_tick: string = `${baseCdnUrl}/assets/static/ic_green_tick.svg`;
export const wishlist_dark_logo: string = `${baseCdnUrl}/assets/logos/img_wishlist_logo_dark.svg`;
export const image_celebration_disabled: string = `${baseCdnUrl}/assets/static/reward/ic_celebration_disabled.svg`;
export const image_gif_disabled: string = `${baseCdnUrl}/assets/static/reward/ic_gif_disabled.svg`;
export const image_disabled: string = `${baseCdnUrl}/assets/static/reward/ic_image_disabled.svg`;
export const image_smily_disabled: string = `${baseCdnUrl}/assets/static/reward/ic_smily_disabled.svg`;
export const preview_icon: string = `${baseCdnUrl}/assets/static/ic_preview.svg`;
export const printer_icon: string = `${baseCdnUrl}/assets/static/ic_printer.svg`;
export const email_icon: string = `${baseCdnUrl}/assets/static/ic_email.svg`;

//#region Amazon Shipping Detail Icons
export const ic_new_releases: string = `${baseCdnUrl}/assets/static/ic_new_releases.svg`;
export const ic_local_shipping: string = `${baseCdnUrl}/assets/static/ic_local_shipping.svg`;
export const ic_no_delivery_info: string = `${baseCdnUrl}/assets/static/ic_no_delivery_info.svg`;
export const reward: string = `${baseCdnUrl}/assets/static/ic_redeem.svg`;
export const colored_gift_icon: string = `${baseCdnUrl}/assets/static/ic_colored-gift.svg`;
export const alternative_image: string = `${baseCdnUrl}/assets/static/ic_add_photo_alternate.svg`;

//#endregion

//#region Marketplace Default Images
export const img_mp_alt_pdp: string = `${baseCdnUrl}/assets/static/img_mp_alt_pdp.svg`;
export const img_mp_alt_product_265_144: string = `${baseCdnUrl}/assets/static/img_mp_alt_product_265_144.svg`;
export const img_mp_alt_236_128: string = `${baseCdnUrl}/assets/static/img_mp_alt_236_128.svg`;
export const img_mp_alt_cat_subcat_80_80: string = `${baseCdnUrl}/assets/static/img_mp_alt_cat_subcat_80_80.svg`;
export const img_mp_alt_brand_265_144: string = `${baseCdnUrl}/assets/static/img_mp_alt_ven_brand_265_144.svg`;
export const img_mp_alt_vendor_265_144: string = `${baseCdnUrl}/assets/static/ic_vendor.svg`;

//#endregion
export const paymarked_card: string = `${baseCdnUrl}/images/products/paymarked-iframe-template.png`;
export const info_icon: string = `${baseCdnUrl}/assets/static/ic_info.svg`;
export const ic_submission_success_icon: string = `${baseCdnUrl}/assets/static/ic_submission_siccess.svg`;
export const img_wishlist_logo: string = `${baseCdnUrl}/assets/static/img_wishlist_logo.svg`;
export const ic_menu: string = `${baseCdnUrl}/assets/static/ic_menu.svg`;
export const img_fulfilled_by_best_buy: string = `${baseCdnUrl}/assets/static/img_fulfilled_by_best_buy.svg`;
export const login_bg: string = `${baseCdnUrl}/assets/static/img_bg_login.png`;
export const img_success_tick_bg_green: string = `${baseCdnUrl}/assets/static/img_success_tick_bg_green.svg`;
export const img_usa_flag: string = `${baseCdnUrl}/assets/static/img_usa_flag.svg`;

//#region userprefernce onboarding 
export const user_preference_bg: string = `../../assets/user_preference/img_right_mob.png`;
//#endregion

//#region MyProfile
export const img_reward_received: string = `${baseCdnUrl}/assets/my_profile/img_reward_received.svg`;//need to upload on CDN
//#endregion
export const my_rewards_file_blue_icon: string = `${baseCdnUrl}/assets/static/reward-specificty/ic_my_rewards_file_blue.svg`;
export const my_rewards_gift_black_icon: string = `${baseCdnUrl}/assets/static/reward-specificty/ic_my_rewards_gift_black.svg`;
export const my_rewards_gift_white_icon: string = `${baseCdnUrl}/assets/static/reward-specificty/ic_my_rewards_gift_white.svg`;
export const my_rewards_help_blue_icon: string = `${baseCdnUrl}/assets/static/reward-specificty/ic_my_rewards_help_blue.svg`;
export const my_rewards_load_more_blue_icon: string = `${baseCdnUrl}/assets/static/reward-specificty/ic_my_rewards_load_more_blue.svg`;
export const my_rewards_wallet_white_icon: string = `${baseCdnUrl}/assets/static/reward-specificty/ic_my_rewards_wallet_white.svg`;
export const my_rewards_header_bg: string = `${baseCdnUrl}/assets/static/reward-specificty/img_reward_specificity_header_bg.png`;
