import { Component, Inject, OnInit, EventEmitter, Optional, OnDestroy } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { image_icon_success_tick_green, image_icon_waiting_clock, image_icon_warn_safron } from 'projects/assets/images/azure';
import { WlSnackbarConfigLiteral, WlSnackbarData, WlSnackbarInfoType, WlSnackbarType, WL_SNACKBAR_CONFIG } from '../model/wl-snackbar.model';

@Component({
  selector: 'wl-snackbar',
  templateUrl: 'wl-snackbar.component.html',
  styleUrls: ['./wl-snackbar.component.scss']
})

export class WlSnackbarComponent implements OnInit, OnDestroy {
  public readonly image_icon_warn_fafron = image_icon_warn_safron;
  public readonly image_icon_waiting_clock = image_icon_waiting_clock;
  public readonly image_icon_success_tick_green = image_icon_success_tick_green;
  public readonly InfoIconType = WlSnackbarInfoType.Info;
  public readonly WarnIconType = WlSnackbarInfoType.Warn;
  public readonly SuccessIconType = WlSnackbarInfoType.Success;
  public readonly NormalSnackbarType = WlSnackbarType.Normal;
  public readonly AlterSnackbarType = WlSnackbarType.Alert;

  public data: WlSnackbarData;
  private dismissByPrimaryAction: EventEmitter<any>;
  private dismissBySecondaryAction: EventEmitter<any>;
  private dismissedByAction = false;

  constructor(@Optional() @Inject(WL_SNACKBAR_CONFIG) private _config: WlSnackbarConfigLiteral = null, private _snackBarRef: MatSnackBarRef<WlSnackbarComponent>, @Inject(MAT_SNACK_BAR_DATA) data: WlSnackbarData) {
    this.dismissByPrimaryAction = new EventEmitter();
    this.dismissBySecondaryAction = new EventEmitter();
    this.data = data;
  }

  ngOnInit(): void {
    this.toggleZendeskChat(true);
  }

  ngOnDestroy(): void {
    this.toggleZendeskChat(false);
  }

  private toggleZendeskChat(hideChat: boolean) {
    hideChat = !this._config?.debug ? hideChat : true;
    this.toggleZendeskOnToaster(hideChat);
  }

  toggleZendeskOnToaster(isHide: boolean) {
    if (isHide) {
      try {
        let ze = window['zE'];
        if (ze) {
          ze('webWidget', 'hide');
        }
        else {
          throw new Error("");
        }
      } catch (e) {
        setTimeout(() => {
          let ze = window['zE'];
          if (ze) {
            ze('webWidget', 'hide');
          }
        }, 1000);
      }
    }
    else {
      try {
        let ze = window['zE'];
        if (ze) {
          ze('webWidget', 'show');
        }
        else {
          throw new Error("");
        }
      } catch (e) {
        setTimeout(() => {
          let ze = window['zE'];
          if (ze) {
            ze('webWidget', 'show');
          }
        }, 1000);
      }
    }
  }

  snackbarDismiss() {
    this._snackBarRef.dismiss()
  }

  getDismissedByPrimaryAction() {
    return this.dismissByPrimaryAction.asObservable();
  }

  getDismissedBySecondaryAction() {
    return this.dismissBySecondaryAction.asObservable();
  }

  dismissedWithPrimaryAction() {
    this.dismissedByAction = true
    this.dismissByPrimaryAction.emit();
    this.snackbarDismiss();
  }

  dismissedWithSecondaryAction() {
    this.dismissedByAction = true
    this.dismissBySecondaryAction.emit();
    this.snackbarDismiss();
  }

  setDismissedByAction(value: boolean) {
    this.dismissedByAction = value;
  }

  wasDismissedByAction(): boolean {
    return this.dismissedByAction;
  }
}
