import { BasicInfo, UserStats } from "../../modals/user.stats"
import { UserStatsActionType } from "./user.stats.action";

export type StatsState = {
    recognitionKinectionSent: number;
    rank: string;
    totalUsers: string;
    companyLeader: BasicInfo;
    runnerUp: BasicInfo;
    comingUp: BasicInfo;
    statsLoaded: boolean;
}

export const initialStatsState: StatsState = {
    recognitionKinectionSent: 0,
    rank: '--',
    totalUsers: '--',
    companyLeader: null,
    runnerUp: null,
    comingUp: null,
    statsLoaded: false
}

export function statsReducer(state: StatsState = initialStatsState, action): StatsState {

    switch (action.type) {
        case UserStatsActionType.loadUserStats:
            {
                let s = action.payload.stats;
                return { ...s, statsLoaded: true };
            }
        case UserStatsActionType.loadUserStatsError:
            {
                return { ...state, statsLoaded: false };
            }

        case UserStatsActionType.UserResetCompleted:
            {
                let data = action.payload.stats;
                return { ...data, statsLoaded: true };
            }

        default:
            return state;
    }
}