import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { UserGuardService } from 'projects/recognition/src/app/providers/authentication/user.guard.service';
import { StoreService } from 'projects/recognition/src/app/store/store.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class WlPreloadStrategyService implements PreloadingStrategy {
  userRoles: string[] = [];
  constructor(private _storeService: StoreService, private _userGuardService: UserGuardService) {
    this._storeService.getUser().subscribe((user) => {
      this.userRoles = [...user.roles]
    })
  }

  preload(route: Route, loadModule: () => Observable<any>): Observable<any> {
    if (this._userGuardService.isLoggedIn()) {
      if (route?.data?.['preload'])
        return loadModule();
      else {
        const currentRoute = window.location.pathname;
        const connection = window.navigator?.['connection'];

        if (connection.effectiveType === '4g' && this.isUserRoleHasAccess(route?.data?.['allowedUserRoles']) && currentRoute?.includes(route?.data?.['parentRoute'])) {
          return loadModule();
        }
        else {
          return of(null);
        }
      }
    } else {
      return of(null);
    }
  }

  isUserRoleHasAccess = (routeRoles: string[]) => (routeRoles?.some(x => this.userRoles.includes(x)));
}