import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";

@Injectable({ providedIn: "root" })
export class InsightApiService {

  constructor(private configService: ConfigService) { }
  getGroupDataList() {
    return this.configService.get("api/insights/groups");
  }
  downloadInsightReport(params: object) {
    return this.configService.get("api/insights/download", params);
  }
  shareInsightReport(params: object) {
    return this.configService.post("api/insights/share", params);
  }
  getProgramDashboard(params: object) {
    return this.configService.get("api/insights/dashboard", params);
  }
  getExpenditure(params: object) {
    return this.configService.get('api/insights/expenditure/spend/status', params);
  }
  getRewardSpendForecast(params: object) {
    return this.configService.get('api/insights/rewards/spend-forecast', params);
  }
  getRewardsLeaderBoard(params: object) {
    return this.configService.get("api/insights/rewards/leader-board", params);
  }
  getRewardsAndRecognitionRecipientRatio(params: object) {
    return this.configService.get("api/insights/rewards-recognition/recipient/ratio", params);
  }
  getRewardsAndRecognitionRecipientInfo(params: object) {
    return this.configService.get("api/insights/rewards-recognition/non/recipients", params);
  }
  getManagementReport(params: object) {
    return this.configService.get("api/insights/management/report", params);
  }
  getTopTagAnalysisTagsInfo(params: object) {
    return this.configService.get("api/insights/top/tags", params);
  }
  getTopTagAnalysisUsersInfo(params: object) {
    return this.configService.get("api/insights/tag/receivers", params);
  }
  getRewardReciprocityDetails(params: object) {
    return this.configService.get("api/insights/reward/reciprocity/details", params);
  }
  getRewardReciprocityUserInfo(params: object) {
    return this.configService.get("api/insights/reward/reciprocity/data", params);
  }
  getLeastInteractionManagerDashboardReport(params: object) {
    return this.configService.get("api/insights/least/interaction", params);
  }
  getRewardReceivedSplit(params: object) {
    return this.configService.get("api/insights/received/rewards/split", params);
  }
  getRewardsRedeemedSplit(params: object) {
    return this.configService.get("api/insights/redeemed/reward/split", params);
  }
  getEngagementOverview(params: object) {
    return this.configService.get("api/insights/engagement/overview", params);
  }
  getSurveyAnalysis(params: object) {
    return this.configService.get("api/insights/survey", params);
  }
  getExpenditureTransactions(params: object) {
    return this.configService.get('api/insights/expenditure/transactions', params);
  }
  getEmployeeEngagement(params: object) {
    return this.configService.get('api/insights/engagement/employee', params)
  }
  getTopOccasionsData = (params: object) => this.configService.get('api/insights/top/occasions/data', params);
  getTopOccasionsList = (params: object) => this.configService.get('api/insights/top/occasions/list', params);
  getEngagementChartData = (params: object) => this.configService.get('api/insights/engagement/groups/list', params);
  getEngagementListData = (params: object) => this.configService.get('api/insights/engagement/groups/data', params);
  getOccasions = (keyword: string = null) => this.configService.get('api/insights/get/occasions', keyword);

  //#region Manager Dashboards Api's 

  getManagerDashboard = (params: object) => this.configService.get("api/insights/manager/dashboard", params);
  getDirectIndirectReportees = (params: object) => this.configService.get("api/insights/reportee/details/get", params)
  getThankyouNotes = (params: object) => this.configService.get("api/insights/thankyounotes/history/get", params);
  getBudgetUnusedAmount = (params: object) => this.configService.get("api/insights/unusedbudget/get", params);
  getIndividualUserDetails = (params: object) => this.configService.get("api/insights/individual/report/get", params);
  getBirthdaysAndMilestones = (params: object) => this.configService.get("api/insights/upcoming/birthday_milestone/get", params);
  getLeastInteractionReportManagerDashboard = (params: object) => this.configService.get("api/insights/leastintraction/report/get", params);
  getFavoriteProductsUser = (params: object) => this.configService.get("api/insights/favorite/products/get", params);
  getPersonalPreferencesProductsUser = (params: object) => this.configService.get("api/insights/personal/preferences/get", params);
  getTopTagsData = (params: object) => this.configService.get("api/insights/toptags/report/get", params);
  getManagersListData = (params: object) => this.configService.get("api/insights/managers/get", params);
  //#endregion Manager Dashboards Api's
}
